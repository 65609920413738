<template>
  <b-overlay :show="isLoading" rounded="sm" class="mh-screen">

    <div v-show="!isLoading" class="w-100 d-flex align-content-between pr-3 pt-0">
      <div v-if="isAllowFilter" class="w-60 text-left filter-line d-flex pl-3">
        <span class="filtry-text pl-0">Filtry:</span>
        <b-form-select
            name="filterTypes"
            v-model="filterType"
            :options="listFilterTypes"
            class="form-select form-select-sm w-230px text-black"
            size="sm"
            @change="changeFilterType"
        />

        <template>
          <b-form-input
              list="zamestnanecList"
              name="zamestnanec"
              class="form-control form-control-sm w-250px ml-2 text-black"
              :placeholder="getCurrentUserName()"
              v-model="filter.zamestnanecName"
              @change="changeZamestnanec"
          />
          <b-datalist
              id="zamestnanecList"
              :options="zamestnancyNames"
              autocomplete="off"
          />
        </template>

        <b-icon-x-circle
            @click="clearFilter"
            class="pl-2 pt-1 cursor-pointer"
            :class="[(filter.userName) ? 'filtered' : '']"
            font-scale="1.8"
            title="Čištění filtru"
        />
      </div>
      <div class="text-right pt-label" :class="isAllowFilter ? 'w-40' : 'w-100'">
        <span class="dark-blue text-nowrap">Měsíční bilance: {{mesicniBilance}}</span>
        <a
           v-if="isShowCestovnyVykaz"
           @click.prevent="generateXlsxCestovniVykaz"
           class="cursor-pointer dark-green badge badge-color-3 ml-3"
        >
          cestovní výkaz
        </a>
      </div>
    </div>

    <div :id="isAllowFilter ? 'CalendarWithFilter' : 'Calendar'" class="pt-1">
      <calendar-view
          class="fve-calendar"
          :show-date="showDate"
          :startingDayOfWeek="1"
          :displayWeekNumbers="true"
          weekdayNameFormat="long"
          :items="items"
          @click-date="clickDate"
          @click-item="clickItem"
      >
        <calendar-view-header
            slot="header"
            slot-scope="t"
            :header-props="t.headerProps"
            :displayLocale="'cs-CZ'"
            :podpisNadrizeneho="podpisNadrizeneho"
            :podpisZamestnance="podpisZamestnance"
            :podpisNadrizenehoName="podpisNadrizenehoName"
            :podpisZamestnanceName="podpisZamestnanceName"
            :reportDate="reportPeriod"
            @input="setShowDate"
            @podpisNadrizenehoChanged="podpisChanged($event, true)"
            @podpisZamestnanceChanged="podpisChanged($event, false)"
            @generatePodkladProVypocetMezd="generatePodkladProVypocetMezd"
            @generateCestovniVykazAllUsers="generateCestovniVykazAllUsers"
        />

        <template #item="{ value: item, top }">
          <div
              :key="item._id"
              :class="` ${getItemClasses(item)}`"
              :style="`top:${top};`"
              @click="clickItem(item)"
          >
            {{ getReportTitle(item) }}
            <template v-if="item.originalItem.typDne === DEN_TYPES.PRACOVNI">
              <br>
              <span class="pl-13px color-grey line-height-18">vykázáno {{ getWorkedMinutesInDay(item) }} hod.</span><br>
              <span class="pl-13px color-grey line-height-18">({{ getCinnostMinutesInDay(item) }} hod. na zakázky / případy)</span>
              <div v-html="getAdditionalInfo(item)" class="d-inline"></div>
            </template>
          </div>
        </template>
      </calendar-view>
    </div>

    <div class="d-flex">
      <div v-show="!isLoading" class="w-100 text-left pl-3 pt-2 mr-2">
        měsíční fond pracovní doby - <b>{{ workingHoursPerMonth }} hod.</b>;
        vykázáno - <span class="dark-blue bold">{{ getHHmmFromMinutes(workedMinutesInMonth) }} hod.</span>;
        vykázáno na zakázky / případy - <span class="dark-blue bold">{{ getHHmmFromMinutes(cinnostMinutesInDay) }} hod.</span>
        <div v-html="getOtherBalanceText" class="d-inline"></div>
      </div>
      <div class="pl-3 text-nowrap pt-2 pr-15px">
        <a
           @click.prevent="monthAutoFill"
           class="cursor-pointer dark-green badge badge-color-3 py-1"
           v-b-tooltip.hover="{
                      title: 'podle vzoru prvního pracovního dne',
                      delay: { show: 200, hide: 200 },
                      variant: 'secondary'
                    }"
        >
          autovyplňování
        </a>
      </div>
    </div>

    <vykaz-prace-tasks-form
        v-if="showModal"
        :show="showModal"
        :date="date"
        :dateFormatted="dateFormatted"
        :dayReport="dayReport"
        :userId="getSelectedUserId()"
        @close-modal="closeVykazPraceTasksForm"
        @form-saved="formSaved"
    />
  </b-overlay>
</template>
<script>

import { CalendarView } from "vue-simple-calendar";
import axios from "axios";
import _ from "lodash";
import VykazPraceTasksForm from '@/components/modals/VykazPraceTasksForm.vue';
import moment from "moment";
import commonMixin from "@/mixins/common.mixin.ts";
import {TYP_DNE, DEN_TYPES, LIST_VYKAZ_PRACE_FILTER_TYPES, FILTER_TYPES, MIME_TYPES} from "@/constants/form.constants";
import CalendarViewHeader from "@/components/Calendar/VykazPraceCalendarViewHeader.vue";
import saveAs from "save-as";

export default {
  name: 'Calendar',
  props: {
    tabName: {type: String, required: true},
  },
  components: {
    CalendarView, CalendarViewHeader, VykazPraceTasksForm,
  },
  mixins: [commonMixin],
  computed: {
    DEN_TYPES() {
      return DEN_TYPES
    },
    getOtherBalanceText() {
      const strings = [];
      if (this.svatekMinutesMonthBalance) {
        strings.push(`placené státní svátky - <span class="dark-red bold">${this.getHHmmFromMinutes(this.svatekMinutesMonthBalance)} hod.</span>`);
      }
      if (this.neschopenkaMinutesMonthBalance) {
        strings.push(`neschopenka - <span class="dark-red bold">${this.getHHmmFromMinutes(this.neschopenkaMinutesMonthBalance)} hod.</span>`);
      }
      if (this.ocrMinutesMonthBalance) {
        strings.push(`OČR - <span class="dark-red bold">${this.getHHmmFromMinutes(this.ocrMinutesMonthBalance)} hod.</span>`);
      }
      if (this.dovolenaMinutesMonthBalance) {
        strings.push(`dovolená - <span class="dark-red bold">${this.getHHmmFromMinutes(this.dovolenaMinutesMonthBalance)} hod.</span>`);
      }
      if (this.nahradniVolnoMinutesMonthBalance) {
        strings.push(`náhradní volno - <span class="dark-red bold">${this.getHHmmFromMinutes(this.nahradniVolnoMinutesMonthBalance)} hod.</span>`);
      }
      return strings.length ? `; ${strings.join('; ')}` : '';
    },
    isAllowFilter() {
      return !!this.$store.getters.isVseVykazyPraceAllowed;
    },
    reportPeriod() {
      return moment(this.balanceDate, 'DD.MM.YYYY').format('MM.YYYY');
    },
    isShowCestovnyVykaz() {
      return this.podpisZamestnance && this.countWithSluzebniCesta;
    },
  },
  data() {
    return {
      listFilterTypes: LIST_VYKAZ_PRACE_FILTER_TYPES,
      filterType: FILTER_TYPES.ZAMESTNANEC,
      showDate: new Date(),
      items: [],
      isLoading: false,
      showModal: false,
      dayReport: {},
      date: null,
      dateFormatted: null,
      lists: {
        typDne: TYP_DNE,
      },
      filter: {
        zamestnanecName: null,
      },
      zamestnancy: [],
      zamestnancyNames: [],
      abortController: new AbortController(),
      mesicniBilance: 0,
      workingHoursPerMonth: 0,
      workedMinutesInMonth: 0,
      cinnostMinutesInDay: 0,
      svatekMinutesMonthBalance: 0,
      neschopenkaMinutesMonthBalance: 0,
      ocrMinutesMonthBalance: 0,
      dovolenaMinutesMonthBalance: 0,
      nahradniVolnoMinutesMonthBalance: 0,
      countWithSluzebniCesta: 0,
      balanceDate: moment().format('DD.MM.YYYY'),
      podpisNadrizeneho: false,
      podpisZamestnance: false,
      podpisZamestnanceName: null,
      podpisNadrizenehoName: null,
    }
  },
  methods: {
    async setShowDate(d) {
      this.showDate = d;
      this.balanceDate = moment(d).format('DD.MM.YYYY');
      await this.updateData();
    },
    clickItem(calendarItem) {
      if (this.podpisNadrizeneho) return;

      this.getDayReport(calendarItem.startDate);
      this.showModal = true;
    },
    clickDate(date) {
      if (this.podpisNadrizeneho) return;

      const selectedMonth = moment(date).format('MM.YYYY');
      if (selectedMonth !== this.reportPeriod) {
        return;
      }

      this.getDayReport(date);
      this.showModal = true;
    },
    getItemClasses(item) {
      const classes = ['cv-item', ...item.classes];
      if (this.podpisNadrizeneho) {
        classes.push('cursor-not-allowed');
      }
      return classes.join(' ');
    },
    getDayReport(date, setDate = true) {
      const dateForItem = moment(date).format('YYYY-MM-DD');
      this.dayReport = this.items.find(i => i.startDate === dateForItem);

      if (!setDate) return;
      this.date = date;
      this.dateFormatted = moment(dateForItem).format('DD.MM.YYYY');
    },
    async getItems() {
      this.abortController.abort();
      this.abortController = new AbortController();

      try {
        this.isLoading = true;
        const filterByUserId = this.getSelectedUserId();
        if (!filterByUserId && this.filter.zamestnanecName) {
          this.isLoading = false;
          return;
        }

        const url = `/calendar/getListVykazPrace`;
        const params = { requiredUserId: filterByUserId, reportPeriod: this.reportPeriod };
        const {data} = await axios.get(url,{ signal: this.abortController.signal, params });

        this.items = _.get(data, 'data.info', []);
        this.items.forEach(i => {
          i.startDate = moment(i.reportDate).format('YYYY-MM-DD');
          i.title = i.typDne;
        });
        this.isLoading = false;
      } catch (e) {
          console.error('get "Výkaz práce" items', e.response?.data);
          this.isLoading = false;
      }
    },
    async formSaved() {
      await Promise.all([
        this.getItems(),
        this.getMesicniBilance(),
      ]);
    },
    closeVykazPraceTasksForm() {
      this.showModal = false;
      this.date = null;
      this.dateFormatted = null;
    },
    getReportTitle(item) {
      const reportTitle = this.getTextByKey(item.title, this.lists.typDne);
      return item.originalItem.homeOffice ? `${reportTitle} (home office)` : reportTitle;
    },
    getWorkedMinutesInDay(item) {
      if (!item.originalItem?.workedMinutesInDay) return '00:00';

      return this.getHHmmFromMinutes(item.originalItem.workedMinutesInDay);
    },
    getCinnostMinutesInDay(item) {
      if (!item.originalItem?.cinnostMinutesInDay) return '00:00';

      return this.getHHmmFromMinutes(item.originalItem.cinnostMinutesInDay);
    },
    async getMesicniBilance() {
      try {
        const filterByUserId = this.getSelectedUserId();
        const url = `/calendar/getMonthBalance`;
        const {data} = await axios.post(url, { balanceDate: this.balanceDate, requiredUserId: filterByUserId });
        this.workedMinutesInMonth = _.get(data, 'data.workedMinutesInMonth', 0);
        this.workingHoursPerMonth = _.get(data, 'data.workingHoursPerMonth', 0);
        const diff = this.workedMinutesInMonth - this.workingHoursPerMonth * 60;
        const diffHHmm = this.getHHmmFromMinutes(Math.abs(diff));
        this.mesicniBilance = diff < 0 ? `minus ${diffHHmm} hod.` : `přesčas ${diffHHmm} hod.`;

        this.cinnostMinutesInDay = _.get(data, 'data.cinnostMinutesInDay', 0);
        this.svatekMinutesMonthBalance = _.get(data, 'data.svatekMinutesMonthBalance', 0);
        this.neschopenkaMinutesMonthBalance = _.get(data, 'data.neschopenkaMinutesMonthBalance', 0);
        this.ocrMinutesMonthBalance = _.get(data, 'data.ocrMinutesMonthBalance', 0);
        this.dovolenaMinutesMonthBalance = _.get(data, 'data.dovolenaMinutesMonthBalance', 0);
        this.nahradniVolnoMinutesMonthBalance = _.get(data, 'data.nahradniVolnoMinutesMonthBalance', 0);
        this.countWithSluzebniCesta = _.get(data, 'data.countWithSluzebniCesta', 0);
      } catch (e) {
        console.error('getMonthBalance', e.response?.data);
      }
    },
    async updateData() {
      await Promise.all([
        this.getItems(),
        this.getMesicniBilance(),
        this.getPodpisy(),
      ]);
    },
    async clearFilter() {
      this.filter.zamestnanecName = null;
      await this.updateData();
    },
    async changeFilterType() {
      await this.updateData();
    },
    async changeZamestnanec() {
      await this.updateData();
    },
    async getActiveUsersList() {
      try {
        if (!this.$store.getters.isVseVykazyPraceAllowed) return;

        const url = `/calendar/vykazPrace/getActiveUsersList`;
        const { data } = await axios.get(url, {});
        this.zamestnancyNames = data?.data?.usersNames || [];
        this.zamestnancy = data?.data?.users || [];
      } catch (error) {
        console.log(error);
      }
    },
    getSelectedUserId() {
      return _.first(this.zamestnancy.filter(u => u.name === this.filter.zamestnanecName))?.id;
    },
    getCurrentUserName() {
      return this.zamestnancy.find(u => u.id === this.$store.getters.userInfo._id)?.name;
    },
    async podpisChanged(e, isNadrizeny = false) {
      try {
        const url = `/calendar/vykazPrace/changePodpis`;
        const userId = this.getSelectedUserId() || this.$store.getters.userInfo._id;
        const body = {
          userId,
          signedById: this.$store.getters.userInfo._id,
          isNadrizeny,
          isSign: e,
          signMonth: this.reportPeriod,
        };
        const {data} = await axios.post(url, body);
        if (data.status === 'success') {
          this.$snotify[data.status](data.data.message);
          await this.getPodpisy();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPodpisy() {
      try {
        const url = `/calendar/vykazPrace/getPodpisy`;
        const userId = this.getSelectedUserId() || this.$store.getters.userInfo._id;
        const body = { userId, signMonth: this.reportPeriod };
        const {data: {data}} = await axios.post(url, body);
        this.podpisNadrizeneho = data?.signInfo?.podpisNadrizeneho || false;
        this.podpisZamestnance = data?.signInfo?.podpisZamestnance || false;
        this.podpisZamestnanceName = data?.signInfo?.podpisZamestnanceName || null;
        this.podpisNadrizenehoName = data?.signInfo?.podpisNadrizenehoName || null;
      } catch (error) {
        console.log(error);
      }
    },
    getFileName() {
      const date = this.reportPeriod.split('.').join('_');
      return `Mzdy_${date}_report_ze_CRM.xlsx`;
    },
    async generatePodkladProVypocetMezd() {
      try {
        const fileName = this.getFileName();
        const url = `/calendar/vykazPrace/generateXlsxPodklad`;
        const {data} = await axios({
          method: "get",
          url: url,
          params: {period: this.reportPeriod},
          responseType: "blob",
        });
        const blob = new Blob([data], { type: `${ MIME_TYPES.xlsx };base64,` });
        this.$snotify['success']('Podklad vygenerováno');
        saveAs(blob, fileName);
      } catch (error) {
        console.log(error);
      }
    },
    getAdditionalInfo(item) {
      const {sluzebniCesta, nahradniVonlo, propustka, kratkaDovolena} = item.originalItem;
      const strings = [];
      const getRedText = (text) => `<span class="dark-red pl-13px font-weight-normal line-height-18">${text}</span>`;
      const getBlueText = (text) => `<span class="dark-blue pl-13px font-weight-normal line-height-18">${text}</span>`;

      if (sluzebniCesta) strings.push(getBlueText(`služební cesta ${ this.getHHMMHod(sluzebniCesta) }`));
      if (nahradniVonlo) strings.push(getRedText(`náhradní volno ${ this.getHHMMHod(nahradniVonlo) }`));
      if (propustka) strings.push(getRedText(`propustka ${ this.getHHMMHod(propustka) }`));
      if (kratkaDovolena) strings.push(getRedText(`krátká dovolená ${ this.getHHMMHod(kratkaDovolena) }`));
      return strings.length ? `<br>${strings.join('<br>')}` : '';
    },
    async monthAutoFill() {
      try {
        const filterByUserId = this.getSelectedUserId();
        const url = `/calendar/vykazPrace/monthAutoFill`;
        const {data} = await axios.post(url, { period: this.reportPeriod, requiredUserId: filterByUserId});
        if (data.status === 'success') {
          this.$snotify[data.status](data.data.message);
          await this.updateData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    getCestovniVykazFileName() {
      try {
        const month = moment(this.showDate).format('MM');
        const year = moment(this.showDate).format('YYYY');
        let zamestnanecName = this.filter.zamestnanecName || this.getCurrentUserName();
        zamestnanecName = zamestnanecName.split(' ').join('_');
        return ['Cestovni_nahrady', month, year, 'report_ze_CRM_pro', zamestnanecName].filter(e => e).join('_') + '.xlsx';
      } catch (e) {
        console.error('getCestovniVykazFileName', e);
      }
    },
    async generateXlsxCestovniVykaz() {
      try {
        const fileName = this.getCestovniVykazFileName();
        const url = `/calendar/vykazPrace/generateXlsxCestovniVykaz`;
        const {data} = await axios({
          method: "get",
          url: url,
          params: {requiredUserId: this.getSelectedUserId(), period: this.reportPeriod},
          responseType: "blob",
        });
        const blob = new Blob([data], { type: `${ MIME_TYPES.xlsx };base64,` });
        this.$snotify['success']('Cestovní výkaz vygenerován');
        saveAs(blob, fileName);
      } catch (e) {
        console.error('generateCestovniVykaz', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async generateCestovniVykazAllUsers() {
      try {
        const date = this.reportPeriod.split('.').join('_');
        const fileName = `Cestovní_náhrady_${date}_report_ze_CRM.zip`;
        const url = `/calendar/vykazPrace/generateXlsxCestovniVykazAllUsersArchive`;
        const {data} = await axios({
          method: "get",
          url: url,
          params: {period: this.reportPeriod},
          responseType: "blob",
        });
        const blob = new Blob([data], { type: `${ MIME_TYPES.zip },` });
        this.$snotify['success']('Vygenerováno');
        saveAs(blob, fileName);
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    if (this.$route.query?.period) {
      this.showDate = moment(this.$route.query.period, 'MM.YYYY').toDate();
      this.balanceDate = `01.${this.$route.query.period}`;
    }

    this.isLoading = true;
    await Promise.all([
      this.getItems(),
      this.getMesicniBilance(),
      this.getActiveUsersList(),
    ]);
    await this.getPodpisy();
    this.isLoading = false;
  },
}
</script>
<style>
#Calendar, #CalendarWithFilter {
  color: #2c3e50;
  height: calc(100vh - 15.5rem);
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}
#CalendarWithFilter {
  height: calc(100vh - 16.5rem) !important;
}
</style>