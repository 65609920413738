<template>
	<div class="cv-header">
		<div class="cv-header-nav">
			<button
				:disabled="!headerProps.previousYear"
				class="previousYear"
				aria-label="Previous Year"
				@click.prevent="onInput(headerProps.previousYear)"
			>
				{{ previousYearLabel }}
			</button>
			<button
				:disabled="!headerProps.previousPeriod"
				class="previousPeriod"
				aria-label="Previous Period"
				@click.prevent="onInput(headerProps.previousPeriod)"
				v-html="previousPeriodLabel"
			/>
			<button
				class="currentPeriod"
				aria-label="Current Period"
				@click.prevent="onInput(headerProps.currentPeriod)"
			>
				{{ headerProps.currentPeriodLabel }}
			</button>
			<button
				:disabled="!headerProps.nextPeriod"
				class="nextPeriod"
				aria-label="Next Period"
				@click.prevent="onInput(headerProps.nextPeriod)"
			>
				{{ nextPeriodLabel }}
			</button>
			<button
				:disabled="!headerProps.nextYear"
				class="nextYear"
				aria-label="Next Year"
				@click.prevent="onInput(headerProps.nextYear)"
			>
				{{ nextYearLabel }}
			</button>
		</div>
		<div class="periodLabel">
			<slot name="label">{{ headerProps.periodLabel }}</slot>
		</div>
    <div v-if="isVseVykazyPraceAllowed" class="periodLabel">
      <a class="fs-15px cursor-pointer light-green-1 badge badge-color-1" @click="generatePodkladProVypocetMezd">Podklad pro vypočet mezd {{ reportDate}}</a>
      <a class="fs-15px ml-3 cursor-pointer dark-green badge badge-color-3" @click="generateCestovniVykazAllUsers">Cestovní náhrady {{ reportDate}}</a>
    </div>
    <div class="d-flex">
      <b-form-checkbox
          id="podpisNadrizeneho"
          v-model="localPodpisNadrizeneho"
          class="pr-5 color-black text-nowrap d-flex"
          :disabled="!isVseVykazyPraceAllowed"
          @change="podpisNadrizenehoChanged()"
      >
        <div class="pt-5px">
          {{ localPodpisNadrizeneho ? `podpis ${podpisNadrizenehoName}` : `podpis nadřízeného` }}
        </div>
      </b-form-checkbox>
      <b-form-checkbox
          id="podpisZamestnance"
          v-model="localPodpisZamestnance"
          class="pr-5 color-black text-nowrap d-flex"
          @change="podpisZamestnanceChanged()"
      >
        <div class="pt-5px">
          {{ localPodpisZamestnance ? `podpis ${podpisZamestnanceName}` : `podpis zaměstnance` }}
        </div>
      </b-form-checkbox>
    </div>
	</div>
</template>
<script>
export default {
	name: "CalendarViewHeader",
	props: {
		headerProps: {
			type: Object,
			required: true,
		},
		previousYearLabel: { type: String, default: "<<" },
		previousPeriodLabel: { type: String, default: "<" },
		nextPeriodLabel: { type: String, default: ">" },
		nextYearLabel: { type: String, default: ">>" },
    podpisNadrizeneho: { type: Boolean, default: false },
    podpisZamestnance: { type: Boolean, default: false },
    podpisNadrizenehoName: { type: String, default: null },
    podpisZamestnanceName: { type: String, default: null },
    reportDate: { type: String, default: null },
	},
  computed: {
    isVseVykazyPraceAllowed() {
      return !!this.$store.getters.isVseVykazyPraceAllowed;
    },
  },
  data() {
    return {
      localPodpisNadrizeneho: false,
      localPodpisZamestnance: false,
    }
  },
	methods: {
		onInput(d) {
			this.$emit("input", d)
		},
    podpisNadrizenehoChanged() {
      this.$emit("podpisNadrizenehoChanged", this.localPodpisNadrizeneho);
    },
    podpisZamestnanceChanged() {
      this.$emit("podpisZamestnanceChanged", this.localPodpisZamestnance);
    },
    generatePodkladProVypocetMezd() {
      this.$emit("generatePodkladProVypocetMezd");
    },
    generateCestovniVykazAllUsers() {
      this.$emit("generateCestovniVykazAllUsers");
    },
	},
  watch: {
    podpisNadrizeneho() {
      this.localPodpisNadrizeneho = this.podpisNadrizeneho;
    },
    podpisZamestnance() {
      this.localPodpisZamestnance = this.podpisZamestnance;
    },
  },
  mounted() {
    this.localPodpisNadrizeneho = this.podpisNadrizeneho;
    this.localPodpisZamestnance = this.podpisZamestnance;
  }
}
</script>
<style>
.cv-header {
	display: flex;
	flex: 0 1 auto;
	flex-flow: row nowrap;
	align-items: center;
	min-height: 2.5em;
	border-width: 1px 1px 0 1px;
}

.cv-header .periodLabel {
	display: flex;
	flex: 1 1 auto;
	flex-flow: row nowrap;
	min-height: 1.5em;
	line-height: 1;
	font-size: 1.5em;
}

.cv-header,
.cv-header button {
	border-style: solid;
	border-color: #ddd;
}

.cv-header-nav,
.cv-header .periodLabel {
	margin: 0.1em 0.6em;
}

.cv-header-nav button,
.cv-header .periodLabel {
	padding: 0.4em 0.6em;
}

.cv-header button {
	box-sizing: border-box;
	line-height: 1em;
	font-size: 1em;
	border-width: 1px;
}


.custom-control-label {
  vertical-align: middle;
  display: initial;
}
</style>
