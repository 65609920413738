<template>
  <b-modal
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="vykaz-prace-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">{{ `Výkaz práce dne ${dateFormatted}, ${formatDayOfWeek}` }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <ValidationProvider :rules="formData.typDne ? 'required' : ''" name="'Typ dne'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                      name="typDne"
                      v-model="formData.typDne"
                      :options="lists.typDne"
                      class="d-inline-block w-200px form-select form-select-sm"
                      size="sm"
                      @change="resetDataToDefault"
                      :title="errors[0]"
                      :state="getState(classes, formData.typDne)"
                  >
                    <template #first>
                      <option value=""></option>
                    </template>
                  </b-form-select>
                </ValidationProvider>
                <ValidationProvider
                    v-if="formData.typDne === DEN_TYPES.NAHRADNI_VOLNO"
                    rules="required|max:50" name="'Poznámka'"
                    v-slot="{ classes, errors }"
                    class="ml-3 w-350px"
                >
                  <b-form-input
                      id="freeDayNote"
                      type="text"
                      size="sm"
                      placeholder="Poznámka"
                      v-model.trim="formData.freeDayNote"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>
                <b-form-checkbox
                    v-if="formData.typDne === DEN_TYPES.PRACOVNI"
                    id="homeOffice"
                    v-model="formData.homeOffice"
                    class="ml-3 pt-label"
                >
                  <div class="pt-2px">home office</div>
                </b-form-checkbox>
              </div>
              <div>
                <span class="dark-blue text-nowrap">Měsíční bilance: {{mesicniBilance}}</span>
              </div>

            </div>
            <template v-if="formData.typDne === DEN_TYPES.PRACOVNI">
              <div class="d-flex pt-3">
                <span class="pt-label bold text-nowrap">Pracovní doba:</span>
                <span class="pt-label pl-2">od</span>
                <vue-timepicker
                    :minute-interval="10"
                    v-model="formData.pracovniDobaOd"
                    auto-scroll
                    :hour-range="[[5, 21]]"
                    hide-disabled-items
                    hide-clear-button
                    lazy
                    input-width="110px"
                    class="time-picker-activity ml-2"
                    input-class="form-control-sm input-timepicker"
                    @input="changeTimeData"
                />
                <span class="pt-label pl-3">do</span>
                <vue-timepicker
                    :minute-interval="10"
                    v-model="formData.pracovniDobaDo"
                    auto-scroll
                    :hour-range="[[formData.pracovniDobaOd.HH, 23]]"
                    hide-disabled-items
                    hide-clear-button
                    lazy
                    input-width="110px"
                    class="time-picker-activity ml-2"
                    input-class="form-control-sm input-timepicker"
                    @input="changeTimeData"
                />
                <span class="pt-label bold pl-4 text-nowrap">Pracovní přestávka:</span><span class="pt-label pl-2">od</span>
                <vue-timepicker
                    :minute-interval="10"
                    v-model="formData.pracovniPrestavkaOd"
                    auto-scroll
                    :hour-range="[[formData.pracovniDobaOd.HH, formData.pracovniDobaDo.HH]]"
                    hide-disabled-items
                    hide-clear-button
                    lazy
                    input-width="110px"
                    class="time-picker-activity ml-2"
                    input-class="form-control-sm input-timepicker"
                    @input="changeTimeData"
                />
                <span class="pt-label pl-3">do</span>
                <vue-timepicker
                    :minute-interval="10"
                    v-model="formData.pracovniPrestavkaDo"
                    auto-scroll
                    :hour-range="[[formData.pracovniPrestavkaOd.HH, formData.pracovniDobaDo.HH]]"
                    hide-disabled-items
                    hide-clear-button
                    lazy
                    input-width="110px"
                    class="time-picker-activity ml-2"
                    input-class="form-control-sm input-timepicker"
                    @input="changeTimeData"
                />
              </div>
              <div class="d-flex pt-3">
                <span class="pt-label text-nowrap">Nadstandardní pracovní doba:</span>
                <span class="pt-label pl-2">od</span>
                <vue-timepicker
                    :minute-interval="10"
                    v-model="formData.nadstandardniDobaOd"
                    auto-scroll
                    :hour-range="[[0, +formData.pracovniDobaOd.HH - 1], [formData.pracovniDobaDo.HH, 23]]"
                    hide-disabled-items
                    hide-clear-button
                    lazy
                    input-width="110px"
                    class="time-picker-activity ml-2"
                    input-class="form-control-sm input-timepicker"
                    @input="changeTimeData"
                />
                <span class="pt-label pl-3">do</span>
                <vue-timepicker
                    :minute-interval="10"
                    v-model="formData.nadstandardniDobaDo"
                    auto-scroll
                    :hour-range="getNadstandardniDobaDoRange"
                    hide-disabled-items
                    hide-clear-button
                    lazy
                    input-width="110px"
                    class="time-picker-activity ml-2"
                    input-class="form-control-sm input-timepicker"
                    @input="changeTimeData"
                />
              </div>

              <div class="d-flex pt-3">
                <div v-if="!formData.sluzebniCesta" class="mr-4">
                  <b-icon-plus-circle
                      @click="addSluzebniCesta()"
                      variant="primary"
                      class="cursor-pointer fs-14px"
                  />
                  <span class="pl-1">Služební cesta</span>
                </div>
                <div v-if="!formData.nahradniVonlo" class="mr-4">
                  <b-icon-plus-circle
                      @click="addNahradniVolno()"
                      variant="primary"
                      class="cursor-pointer fs-14px"
                  />
                  <span class="pl-1">Náhradní volno</span>
                </div>
                <div v-if="!formData.propustka" class="mr-4">
                  <b-icon-plus-circle
                      @click="addPropustka()"
                      variant="primary"
                      class="cursor-pointer fs-14px"
                  />
                  <span class="pl-1">Propustka</span>
                </div>
                <div v-if="!formData.kratkaDovolena">
                  <b-icon-plus-circle
                      @click="addKratkaDovolena()"
                      variant="primary"
                      class="cursor-pointer fs-14px"
                  />
                  <span class="pl-1">Krátká dovolená</span>
                </div>
              </div>

              <div class="d-flex flex-column">
                <b-card v-if="formData.sluzebniCesta" class="mt-3 w-100">
                  <div class="w-100 d-flex">
                    <div class="w-100 mr-80px">
                      <div class="d-flex">
                        <span class="pt-label mw-200px"><b>Služební cesta:</b> {{ getHHMMHod(formData.sluzebniCesta) }}</span>
                        <span class="pt-label pl-2">od</span>
                        <vue-timepicker
                            :minute-interval="10"
                            v-model="formData.sluzebniCesta.timeOd"
                            auto-scroll
                            hide-disabled-items
                            hide-clear-button
                            lazy
                            input-width="110px"
                            class="time-picker-activity ml-2"
                            input-class="form-control-sm input-timepicker"
                            @change="changeSluzebniCestaTime"
                        />
                        <span class="pt-label pl-3">do</span>
                        <vue-timepicker
                            :minute-interval="10"
                            v-model="formData.sluzebniCesta.timeDo"
                            auto-scroll
                            :hour-range="getSluzebniCestaDoRange"
                            hide-disabled-items
                            hide-clear-button
                            lazy
                            input-width="110px"
                            class="time-picker-activity ml-2 mr-2"
                            input-class="form-control-sm input-timepicker"
                            @change="changeSluzebniCestaTime"
                        />
                        <ValidationProvider :rules="`max:${noteLength}`" name="'Poznámka/Služební cesta'" v-slot="{ classes, errors }" class="ml-2 w-100">
                          <b-form-input
                              id="sluzebniCestaNote"
                              type="text"
                              size="sm"
                              placeholder="Poznámka"
                              v-model.trim="formData.sluzebniCesta.note"
                              :title="errors[0]"
                              :state="getState(classes)"
                          />
                        </ValidationProvider>
                      </div>
                      <div class="d-flex pt-2">
                        <span class="text-nowrap pt-label"><b>trasa</b> (odkud a kam)</span>
                        <ValidationProvider rules="required|max:50" name="'Trasa'" v-slot="{ classes, errors }" class="ml-3 w-300px">
                          <b-form-input
                              id="trasa"
                              type="text"
                              size="sm"
                              v-model.trim="formData.sluzebniCesta.trasa"
                              :title="errors[0]"
                              :state="getState(classes)"
                          />
                        </ValidationProvider>

                        <ValidationProvider
                            :rules="{ mustBeInList: { list: obhodniPripadyList }}"
                            name="'sluzebniCestaOderNumber'" mode="lazy" v-slot="{ classes, errors }"
                            class="w-50 mr-3"
                        >
                          <b-form-input
                              id="sluzebniCestaOrderNumber"
                              list="sluzebniCestaOPPList"
                              name="sluzebniCestaOrderNumber"
                              class="form-control form-control-sm ml-3 w-100"
                              placeholder="Obchodní případ / zakázka"
                              v-model.trim="formData.sluzebniCesta.orderNumber"
                              :title="errors[0]"
                              :state="getState(classes) && !validateWithDataFromList('formData.sluzebniCesta.orderNumber', null,'obhodniPripadyList', true)"
                              v-debounce="searchObchodniPripady"
                          />
                        </ValidationProvider>
                        <b-datalist
                            id="sluzebniCestaOPPList"
                            :options="obhodniPripadyList"
                            autocomplete="off"
                        />
                      </div>
                      <div class="d-flex pt-2">
                        <span class="text-nowrap pt-label"><b>doprava</b> (SPZ auta)</span>
                        <ValidationProvider rules="required|max:10" name="'Doprava'" v-slot="{ classes, errors }" class="ml-2 w-100px">
                          <b-form-input
                              id="doprava"
                              type="text"
                              size="sm"
                              v-model.trim="formData.sluzebniCesta.doprava"
                              :title="errors[0]"
                              :state="getState(classes)"
                          />
                        </ValidationProvider>

                        <span class="text-nowrap pt-label ml-3"><b>řidič</b></span>
                        <b-form-input
                            id="ridic"
                            type="text"
                            size="sm"
                            v-model="formData.sluzebniCesta.ridic"
                            class="ml-2 w-120px"
                            maxlength="50"
                        />

                        <span class="text-nowrap pt-label ml-3"><b>Ubytování</b></span>
                        <b-form-select
                            id="ubytovani"
                            name="ubytovani"
                            v-model="formData.sluzebniCesta.ubytovani"
                            :options="UBYTOVANI_OPTIONS"
                            class="ml-2 form-select form-select-sm w-65px"
                            size="sm"
                        />

                        <span class="ml-3 pt-label text-nowrap">сestovní náhrada {{nahrada}} Kč</span>
                      </div>
                    </div>
                    <div class="mw-100px close-block">
                      <b-icon-x-circle
                          class="cursor-pointer remove-icon fs-14px"
                          @click="clearSluzebniCesta"
                          v-b-tooltip.hover="{
                            title: 'Smazat',
                            delay: { show: 200, hide: 200 },
                            variant: 'secondary'
                          }"
                      />
                      <span class="pt-label remove-icon pl-2">smazat</span>
                    </div>
                  </div>
                </b-card>

                <b-card v-if="formData.nahradniVonlo" class="mt-3 w-100">
                  <div class="d-flex mr-80px">
                    <span class="pt-label mw-200px"><b>Náhradní volno:</b> {{ getHHMMHod(formData.nahradniVonlo) }}</span>
                    <span class="pt-label pl-2">od</span>
                    <vue-timepicker
                        :minute-interval="10"
                        v-model="formData.nahradniVonlo.timeOd"
                        auto-scroll
                        hide-disabled-items
                        hide-clear-button
                        lazy
                        input-width="110px"
                        class="time-picker-activity ml-2"
                        input-class="form-control-sm input-timepicker"
                        @input="changeTimeData"
                    />
                    <span class="pt-label pl-3">do</span>
                    <vue-timepicker
                        :minute-interval="10"
                        v-model="formData.nahradniVonlo.timeDo"
                        auto-scroll
                        :hour-range="getNahradniVolnoDoRange"
                        hide-disabled-items
                        hide-clear-button
                        lazy
                        input-width="110px"
                        class="time-picker-activity ml-2 mr-2"
                        input-class="form-control-sm input-timepicker"
                        @input="changeTimeData"
                    />
                    <ValidationProvider :rules="`max:${noteLength}`" name="'Poznámka/Náhradní volno'" v-slot="{ classes, errors }" class="ml-2 w-100">
                      <b-form-input
                          id="nahradniVonloNote"
                          type="text"
                          size="sm"
                          placeholder="Poznámka"
                          v-model.trim="formData.nahradniVonlo.note"
                          :title="errors[0]"
                          :state="getState(classes)"
                      />
                    </ValidationProvider>
                    <div class="mw-100px close-block">
                      <b-icon-x-circle
                          class="cursor-pointer remove-icon fs-14px"
                          @click="formData.nahradniVonlo = null; changeTimeData()"
                          v-b-tooltip.hover="{
                            title: 'Smazat',
                            delay: { show: 200, hide: 200 },
                            variant: 'secondary'
                          }"
                      />
                      <span class="pt-label remove-icon pl-2">smazat</span>
                    </div>
                  </div>
                </b-card>

                <b-card v-if="formData.propustka" class="mt-3 w-100">
                  <div class="d-flex mr-80px">
                    <span class="pt-label mw-200px"><b>Propustka:</b> {{ getHHMMHod(formData.propustka) }}</span>
                    <span class="pt-label pl-2">od</span>
                    <vue-timepicker
                        :minute-interval="10"
                        v-model="formData.propustka.timeOd"
                        auto-scroll
                        hide-disabled-items
                        hide-clear-button
                        lazy
                        input-width="110px"
                        class="time-picker-activity ml-2"
                        input-class="form-control-sm input-timepicker"
                    />
                    <span class="pt-label pl-3">do</span>
                    <vue-timepicker
                        :minute-interval="10"
                        v-model="formData.propustka.timeDo"
                        auto-scroll
                        :hour-range="getPropustkaDoRange"
                        hide-disabled-items
                        hide-clear-button
                        lazy
                        input-width="110px"
                        class="time-picker-activity ml-2 mr-2"
                        input-class="form-control-sm input-timepicker"
                    />
                    <ValidationProvider :rules="`max:${noteLength}`" name="'Poznámka/Propustka'" v-slot="{ classes, errors }" class="ml-2 w-100">
                      <b-form-input
                          id="propustkaNote"
                          type="text"
                          size="sm"
                          placeholder="Poznámka"
                          v-model.trim="formData.propustka.note"
                          :title="errors[0]"
                          :state="getState(classes)"
                      />
                    </ValidationProvider>
                    <div class="mw-100px close-block">
                      <b-icon-x-circle
                          class="cursor-pointer remove-icon fs-14px"
                          @click="formData.propustka = null; changeTimeData()"
                          v-b-tooltip.hover="{
                            title: 'Smazat',
                            delay: { show: 200, hide: 200 },
                            variant: 'secondary'
                          }"
                      />
                      <span class="pt-label remove-icon pl-2">smazat</span>
                    </div>
                  </div>
                </b-card>

                <b-card v-if="formData.kratkaDovolena" class="mt-3 w-100">
                  <div class="d-flex mr-80px">
                    <span class="pt-label mw-200px text-nowrap"><b>Krátká dovolená:</b> {{ getHHMMHod(formData.kratkaDovolena) }}</span>
                    <span class="pt-label pl-2">od</span>
                    <vue-timepicker
                        :minute-interval="10"
                        v-model="formData.kratkaDovolena.timeOd"
                        auto-scroll
                        hide-disabled-items
                        hide-clear-button
                        lazy
                        input-width="110px"
                        class="time-picker-activity ml-2"
                        input-class="form-control-sm input-timepicker"
                        @input="changeTimeData"
                    />
                    <span class="pt-label pl-3">do</span>
                    <vue-timepicker
                        :minute-interval="10"
                        v-model="formData.kratkaDovolena.timeDo"
                        auto-scroll
                        :hour-range="getKratkaDovolenaDoRange"
                        hide-disabled-items
                        hide-clear-button
                        lazy
                        input-width="110px"
                        class="time-picker-activity ml-2 mr-2"
                        input-class="form-control-sm input-timepicker"
                        @input="changeTimeData"
                    />
                    <ValidationProvider :rules="`max:${noteLength}`" name="'Poznámka/Krátká dovolená'" v-slot="{ classes, errors }" class="ml-2 w-100">
                      <b-form-input
                          id="kratkaDovolenaNote"
                          type="text"
                          size="sm"
                          placeholder="Poznámka"
                          v-model.trim="formData.kratkaDovolena.note"
                          :title="errors[0]"
                          :state="getState(classes)"
                      />
                    </ValidationProvider>
                    <div class="mw-100px close-block">
                      <b-icon-x-circle
                          class="cursor-pointer remove-icon fs-14px"
                          @click="formData.kratkaDovolena = null; changeTimeData()"
                          v-b-tooltip.hover="{
                            title: 'Smazat',
                            delay: { show: 200, hide: 200 },
                            variant: 'secondary'
                          }"
                      />
                      <span class="pt-label remove-icon pl-2">smazat</span>
                    </div>
                  </div>
                </b-card>

              </div>

              <hr class="pt-2 mb-2">

              <div class="">
                <span class="pt-label bold text-nowrap">Činnost (naplň práce):</span>
              </div>
              <div v-for="(cinnost, indx) in formData.cinnost" :key="'cinnost'+indx" class="d-flex pt-2">
                <cinnost-line
                    :cinnost="cinnost"
                    :index="indx"
                    @validation-error="handleValidationError"
                    @change-data="changeCinnostData"
                />
              </div>
            </template>


            <div v-if="errFillTime" class="errMsg-color pt-4 text-center">
              Součet hodin náplně práce nesmí přesahovat odpracované hodiny
            </div>

            <div class="d-flex justify-content-center w-100>">
              <b-button type="submit" class="mt-4 mb-1" variant="primary" :disabled="errFillTime">Uložit změny</b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import commonMixin from "@/mixins/common.mixin.ts";
import {mapGetters} from "vuex";
import moment from "moment";
import 'moment/locale/cs';
import {TYP_DNE, DEN_TYPES} from "@/constants/form.constants";
import VueTimepicker from 'vue2-timepicker';
import CinnostLine from "@/components/VykazHodin/CinnostLine.vue";
import axios from "axios";
import _ from "lodash";

const emptyTime = {HH: '00', mm: '00'};
const emptyCinnost = {time: _.cloneDeep(emptyTime), contractId: null, note: null};
const noteLength = 100;

export default {
  mixins: [commonMixin],
  components: {VueTimepicker, CinnostLine},
  computed: {
    DEN_TYPES() {
      return DEN_TYPES
    },
    formatDayOfWeek() {
      const formattedDate = moment(this.dateFormatted, 'DD.MM.YYYY').locale('cs');
      return formattedDate.format('dddd');
    },
    getNadstandardniDobaDoRange() {
      const range = [];
      if (+this.formData.nadstandardniDobaOd.HH < +this.formData.pracovniDobaOd.HH) {
        range.push([this.formData.nadstandardniDobaOd.HH, this.formData.pracovniDobaOd.HH]);
      } else if (+this.formData.nadstandardniDobaOd.HH >= +this.formData.pracovniDobaDo.HH) {
        range.push([this.formData.nadstandardniDobaOd.HH, 23]);
      }
      return range;
    },
    getSluzebniCestaDoRange() {
      return [[this.formData.sluzebniCesta?.timeOd?.HH, 23]];
    },
    getNahradniVolnoDoRange() {
      return [[this.formData.nahradniVonlo?.timeOd?.HH, 23]];
    },
    getPropustkaDoRange() {
      return [[this.formData.propustka?.timeOd?.HH, 23]];
    },
    getKratkaDovolenaDoRange() {
      return [[this.formData.kratkaDovolena?.timeOd?.HH, 23]];
    },
    errFillTime() {
      if (this.formData.typDne !== DEN_TYPES.PRACOVNI) {
        return false;
      }

      return this.workedMinutesInDay < this.cinnostMinutesInDay;
    },
    ...mapGetters(['getUserNamesAndTitles']),
  },
  props: {
    date: { type: Date },
    dateFormatted: { type: String },
    dayReport: { type: Object },
    show: { type: Boolean },
    userId: { type: String },
  },
  data() {
    return {
      noteLength,
      isLoading: false,
      showLocal: false,
      validationError: false,
      id: null,
      nahrada: 0,
      formData: {
        typDne: null,
        freeDayNote: null,
        homeOffice: null,
        pracovniDobaOd: null,
        pracovniDobaDo: null,
        nadstandardniDobaOd: _.cloneDeep(emptyTime),
        nadstandardniDobaDo: _.cloneDeep(emptyTime),
        pracovniPrestavkaOd: null,
        pracovniPrestavkaDo: null,
        cinnost: [_.cloneDeep(emptyCinnost), _.cloneDeep(emptyCinnost), _.cloneDeep(emptyCinnost), _.cloneDeep(emptyCinnost), _.cloneDeep(emptyCinnost)],
        orderNumber: null,
        workedMinutesInMonth: 0,
        workingHoursPerMonth: 0,
        sluzebniCesta: null,
        nahradniVonlo: null,
        propustka: null,
        kratkaDovolena: null,
      },
      savedWorkedMinutesInDay: 0,
      workedMinutesInDay: 0,
      cinnostMinutesInDay: 0,
      mesicniBilance: 0,
      lists: {
        typDne: TYP_DNE,
      },
      obhodniPripadyList: [],
      obhodniPripadyListFull: [],
      UBYTOVANI_OPTIONS: [
        {value: false, text: 'ne'},
        {value: true, text: 'ano'},
      ],
    }
  },
  async mounted() {
    this.isLoading = true;
    this.id = _.cloneDeep(this.userId) || this.$store.getters.userInfo._id;
    await this.resetDataToDefault(false);
    await this.getItemInfo();
    this.getCinnostMinutesInDay();

    if (this.formData?.sluzebniCesta?.orderNumber) {
      this.obhodniPripadyList = [this.formData.sluzebniCesta.orderNumber];
      this.obhodniPripadyListFull = [{orderNumber: this.formData.sluzebniCesta.orderNumber, contractId: this.formData.sluzebniCesta.contractId}];
      this.formData.sluzebniCesta.ubytovani = this.formData.sluzebniCesta?.ubytovani || false;
    }

    this.isLoading = false;
    this.showLocal = this.show;
  },
  methods: {
    async onSubmit() {
      try {
        if (this.validationError) {
          return;
        }

        this.isLoading = true;
        const url = `/calendar/saveVykazPrace`;
        this.formData.reportDate = moment(this.dateFormatted, 'DD.MM.YYYY').locale('cs').set({hour: 12});
        if (!_.isEmpty(this.formData.sluzebniCesta)) {
          this.formData.sluzebniCesta.contractId = this.getContractIdForSluzebniCesta();
        }
        const {data} = await axios.post(url, { id: this.dayReport?._id, formData: this.formData, requiredUserId: this.userId });
        this.$snotify[data.status](data.data.message);
        this.$emit('close-modal');
        this.$emit('form-saved');
      } catch (e) {
        this.isLoading = false;
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getItemInfo() {
      try {
        this.isLoading = true;
        const url = `/calendar/getVykazPraceInfo`;
        const {data} = await axios.get(url, { params: { id: this.dayReport?._id, date: this.dateFormatted, requiredUserId: this.userId } });
        const info = _.get(data, 'data.info', {});
        this.formData = {
          ...this.formData,
          ...info,
        };
        this.savedWorkedMinutesInDay = info.workedMinutesInDay || 0;
        this.workedMinutesInDay = info.workedMinutesInDay || 0;
        this.nahrada = info.sluzebniCestaNagrada || this.nahrada;
        this.getMesicniBilance();
        this.isLoading = false;
      } catch (e) {
        console.error('get "Výkaz práce" items', e.response?.data);
        this.isLoading = false;
      }
    },
    async calculateVykazPraceTimePeriods() {
      try {
        const url = `/calendar/calculateVykazPraceTimePeriods`;
        const {data} = await axios.post(url, { formData: this.formData, id: this.id });
        this.workedMinutesInDay = _.get(data, 'data.workedMinutesInDay', 0);
        this.getMesicniBilance();
      } catch (e) {
        console.error('calculateVykazPraceTimePeriods', e.response?.data);
      }
    },
    async resetDataToDefault(isRecalculate = true) {
      const typDne = this.formData?.typDne;
      if (typDne === DEN_TYPES.PRACOVNI) {
        this.formData.typDne = DEN_TYPES.PRACOVNI;
        this.formData.pracovniDobaOd = {HH: "07", mm: "00"};
        this.formData.pracovniDobaDo = {HH: "15", mm: "30"};
        this.formData.nadstandardniDobaOd = _.cloneDeep(emptyTime);
        this.formData.nadstandardniDobaDo = _.cloneDeep(emptyTime);
        this.formData.pracovniPrestavkaOd = {HH: "12", mm: "00"};
        this.formData.pracovniPrestavkaDo = {HH: "12", mm: "30"};
      } else {
        this.formData.homeOffice = null;
        this.formData.freeDayNote = null;
        this.formData.pracovniDobaOd = _.cloneDeep(emptyTime);
        this.formData.pracovniDobaDo = _.cloneDeep(emptyTime);
        this.formData.nadstandardniDobaOd = _.cloneDeep(emptyTime);
        this.formData.nadstandardniDobaDo = _.cloneDeep(emptyTime);
        this.formData.pracovniPrestavkaOd = _.cloneDeep(emptyTime);
        this.formData.pracovniPrestavkaDo = _.cloneDeep(emptyTime);
        this.formData.cinnost = [_.cloneDeep(emptyCinnost), _.cloneDeep(emptyCinnost), _.cloneDeep(emptyCinnost), _.cloneDeep(emptyCinnost), _.cloneDeep(emptyCinnost)];
      }
     if (isRecalculate) {
       await this.calculateVykazPraceTimePeriods();
     }
    },
    handleValidationError(error) {
      this.validationError = error;
    },
    getMesicniBilance() {
      const needToWorkPerMonthMinutes = this.formData.workingHoursPerMonth * 60;
      const workedMinutesInMonth = this.formData.workedMinutesInMonth;
      const unsavedWorkedMinutes = this.savedWorkedMinutesInDay - this.workedMinutesInDay;
      const diff = workedMinutesInMonth - needToWorkPerMonthMinutes - unsavedWorkedMinutes;
      const diffHHmm = this.getHHmmFromMinutes(Math.abs(diff));
      this.mesicniBilance = diff < 0 ? `minus ${diffHHmm} hod.` : `přesčas ${diffHHmm} hod.`;
    },
    changeCinnostData({ cinnost, index }) {
      this.formData.cinnost[index] = cinnost;
      this.getCinnostMinutesInDay();
    },
    getCinnostMinutesInDay() {
      this.cinnostMinutesInDay = 0;
      this.formData.cinnost.filter(Boolean).forEach(cinnost => {
        const timeMs = this.getTimeMs(cinnost.time) - this.getTimeMs({HH: 0, mm: 0});
        this.cinnostMinutesInDay += Math.ceil(timeMs / (60 * 1000));
      });
      this.cinnostMinutesInDay = this.roundToNearestTen(this.cinnostMinutesInDay);
    },
    async changeTimeData() {
      await this.calculateVykazPraceTimePeriods();
    },
    addSluzebniCesta() {
      this.formData.sluzebniCesta = {
        timeOd: _.cloneDeep(emptyTime),
        timeDo: _.cloneDeep(emptyTime),
        trasa: '',
        ubytovani: false,
      };
    },
    addNahradniVolno() {
      this.formData.nahradniVonlo = {
        timeOd: _.cloneDeep(emptyTime),
        timeDo: _.cloneDeep(emptyTime),
      };
    },
    addPropustka() {
      this.formData.propustka = {
        timeOd: _.cloneDeep(emptyTime),
        timeDo: _.cloneDeep(emptyTime),
      };
    },
    addKratkaDovolena() {
      this.formData.kratkaDovolena = {
        timeOd: _.cloneDeep(emptyTime),
        timeDo: _.cloneDeep(emptyTime),
      };
    },
    fillObhodniPripadyList(list) {
      this.obhodniPripadyList = [];
      list.map(info => {
        this.obhodniPripadyList.push(info.orderNumber);
      });
      this.obhodniPripadyListFull = list;
    },
    async searchObchodniPripady() {
      try {
        if (this.orderNumber?.length < 3 || this.getContractIdForSluzebniCesta()) {
          return;
        }

        const url = `/calendar/searchObchodniPripady`;
        const {data: {data}} = await axios.get(url, {params: {search: this.formData.sluzebniCesta?.orderNumber}});
        this.fillObhodniPripadyList(data?.info || []);
      } catch (e) {
        console.error(e);
      }
    },
    async calculateSluzebniCestaNagrada() {
      try {
        const timeOd = this.formData.sluzebniCesta?.timeOd;
        const timeDo = this.formData.sluzebniCesta?.timeDo;
        if (!timeOd || !timeDo) {
          return;
        }

        const url = `/calendar/calculateSluzebniCestaNagrada`;
        const {data: {data}} = await axios.post(url, {timeOd, timeDo});
        this.nahrada = data?.cestaNagrada || 0;
      } catch (e) {
        console.error(e);
      }
    },
    async changeSluzebniCestaTime() {
      await this.calculateSluzebniCestaNagrada();
    },
    getContractIdForSluzebniCesta() {
      const contract = this.obhodniPripadyListFull.find(info => info.orderNumber === this.formData.sluzebniCesta?.orderNumber);
      return contract?.contractId;
    },
    validateWithDataFromList(key, isInput, listName, isCanBeEmpty = false) {
      if (isCanBeEmpty && !this[key]) return false;
      const isEqual = this[listName || key].includes(_.get(this, key));
      return isInput && !isEqual ? false : !isEqual;
    },
    roundToNearestTen(value) {
      return Math.round(value / 10) * 10;
    },
    clearSluzebniCesta() {
      this.nahrada = 0;
      this.formData.sluzebniCesta = null;
      this.changeTimeData();
    },
  }
}
</script>

<style scoped>
  h6 {
    font-weight: 500;
    font-size: 28px;
  }
  .close-block {
    top: 4px;
    position: absolute;
    left: calc(100% - 80px);
  }
</style>