<template>
  <div class="mh-screen">
    <div class="text-left button-line pl-3">
      <tab-buttons :tabName="tabName" :tabs="TABS" class="pb-4 text-left" @click="changeTab" />
    </div>

    <div v-if="tabName === KALENDAR_TABS.VYKAZ_PRACE">
      <vykaz-prace-tab :tabName="KALENDAR_TABS.VYKAZ_PRACE" />
    </div>
    <div v-else-if="tabName === KALENDAR_TABS.AKTIVITY_VLASTNI">
      <aktivity-vlastni-tab :tabName="KALENDAR_TABS.AKTIVITY_VLASTNI" />
    </div>
    <div v-else>
      <aktivity-obchodu-tab tabName="kalendar" />
    </div>

  </div>
</template>
<script>

import TabButtons from "@/components/TabButtons.vue";
import AktivityObchoduTab from "@/views/Calendar/AktivityObchoduTab.vue";
import VykazPraceTab from "@/views/Calendar/VykazPraceTab.vue";
import AktivityVlastniTab from "@/views/Calendar/AktivityVlastniTab.vue";
import {KALENDAR_TABS} from "@/constants/form.constants";

const TABS = [
  {tabName: KALENDAR_TABS.KALENDAR, link: '/kalendar', name: 'Aktivity obchodu'},
  {tabName: KALENDAR_TABS.AKTIVITY_VLASTNI, link: '/kalendar/aktivity-vlastni', name: 'Aktivity vlastní'},
  {tabName: KALENDAR_TABS.VYKAZ_PRACE, link: '/kalendar/vykaz-prace', name: 'Výkaz práce'},
];

export default {
  name: 'KalendarPage',
  props: {
    tabName: {type: String, required: true},
  },
  components: {
    TabButtons, AktivityObchoduTab, VykazPraceTab, AktivityVlastniTab,
  },
  computed: {
    isShowAktivityObchodu() {
      return this.$store.getters.isAktivityObchoduAllowed;
    },
  },
  mixins: [],
  data() {
    return {
      TABS,
      KALENDAR_TABS,
    }
  },
  methods: {
    async changeTab() {
      // console.log('changeTab');
    },
  },
  mounted() {
    if (!this.isShowAktivityObchodu) {
      this.TABS = this.TABS.filter(tab => tab.tabName !== KALENDAR_TABS.KALENDAR);
    }
  }
}
</script>
<style>

</style>