<template>
  <b-overlay :show="isLoading" rounded="sm" class="mh-screen">

    <div id="Calendar" class="pt-0">
      <calendar-view
          class="fve-calendar"
          :show-date="showDate"
          :startingDayOfWeek="1"
          :displayWeekNumbers="true"
          weekdayNameFormat="long"
          :items="items"
          @click-date="clickDate"
          @click-item="clickItem"
      >
        <calendar-view-header
            slot="header"
            slot-scope="t"
            :header-props="t.headerProps"
            :displayLocale="'cs-CZ'"
            @input="setShowDate"
        />


      </calendar-view>
    </div>

  </b-overlay>
</template>
<script>

import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import commonMixin from "@/mixins/common.mixin.ts";

export default {
  name: 'Calendar',
  props: {
    tabName: {type: String, required: true},
  },
  components: {
    CalendarView, CalendarViewHeader,
  },
  mixins: [commonMixin],
  computed: {

  },
  data() {
    return {
      showDate: new Date(),
      items: [],
      isLoading: false,
      showModal: false,
      abortController: new AbortController(),
    }
  },
  methods: {
    setShowDate(d) {
      this.showDate = d;
    },
    clickItem(/*calendarItem*/) {
      // this.getDayReport(calendarItem.startDate);
      // this.showModal = true;
    },
    clickDate(/*date*/) {
      // this.getDayReport(date);
      // this.showModal = true;
    },
    getItemClasses(item) {
      const classes = ['cv-item', ...item.classes];
      return classes.join(' ');
    },
  },
  async mounted() {
    this.isLoading = true;
    // await this.getItems();
    this.isLoading = false;
  },
}
</script>
<style>
#Calendar {
  color: #2c3e50;
  height: calc(100vh - 15.5rem);
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

</style>