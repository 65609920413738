<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="calendar-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">{{ `Plánované aktivity na ${date}` }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <div v-for="(activity, indx) in dayActivitiesLocal" :key="indx + 'dayActivitiesLocal'">
          <aktivity
              :indx="indx"
              :activity="activity"
              :activityTypeCodes="activityTypeCodes"
              :obchodniZastupceListById="obchodniZastupceListById"
              @show-stav-obchodniho-pripadu-form="showStavObchodnihoPripaduForm(activity)"
              @show-gdpr-form="showGDPRForm(activity)"
              @show-poznamky-k-obchodnimu-pripadu="showPoznamkyKobchodnimuPripaduForm(activity)"
              @form-saved="formSaved"
          />
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import commonMixin from "@/mixins/common.mixin.ts";
import {mapGetters} from "vuex";
import Aktivity from "@/components/Aktivity.vue";

export default {
  mixins: [commonMixin],
  components: {Aktivity},
  computed: {
    isHaveAccess() {
      return this.$store.getters.isObchodPageAllowed;
    },
    ...mapGetters(['getUserNamesAndTitles']),
  },
  props: {
    date: { type: String },
    dayActivities: { type: Array },
    show: { type: Boolean },
    activityTypeCodes: { type: Object },
    obchodniZastupceListById: { type: Object },
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      dayActivitiesLocal: this.dayActivities,
    }
  },
  async mounted() {
    this.isLoading = true;
    this.isLoading = false;
    this.showLocal = this.show;
  },
  methods: {
    showStavObchodnihoPripaduForm(activity) {
      this.$emit('show-stav-obchodniho-pripadu-form', activity?.contractId, activity?.kodZakazky);
    },
    showGDPRForm(activity) {
      this.$emit('show-gdpr-form', activity?.contractId, activity?.kodZakazky);
    },
    showPoznamkyKobchodnimuPripaduForm(activity) {
      this.$emit('show-poznamky-k-obchodnimu-pripadu', activity?.contractId, activity?.kodZakazky);
    },
    async formSaved() {
      await this.$store.dispatch('getActivitiesCount');
    },
  }
}
</script>

<style scoped>
  h6 {
    font-weight: 500;
    font-size: 28px;
  }
</style>