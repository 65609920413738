<template>
  <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
    <div class="text-left filter-line d-flex pl-3">
      <span class="filtry-text pl-0">Filtry:</span>
      <b-form-select
          name="filterTypes"
          v-model="filterType"
          :options="listFilterTypes"
          class="form-select form-select-sm w-230px text-black"
          size="sm"
          @change="changeFilterType"
      />

      <template v-if="isFilterTypeNazev">
        <b-form-input
            id="searchString"
            type="text"
            size="sm"
            v-model.trim="filter.searchString"
            class="w-250px ml-3 text-black"
            :class="[filter.searchString ? 'input-filtered' : '']"
            placeholder="napište co hledáte..."
            v-debounce="searchByString"
        />
      </template>
      <template v-else-if="isFilterTypeKraj">
        <b-form-select
            name="stav"
            v-model="krajOption"
            :options="KRAJ"
            class="d-inline-block w-250px ml-3 form-select form-select-sm"
            size="sm"
            @change="changeKrajSelect"
        >
          <template #first>
            <option value=""></option>
          </template>
        </b-form-select>
      </template>
      <template v-else-if="isTypeActivity">
        <b-form-select
            name="stav"
            v-model="typeActivityOption"
            :options="lists.typeActivity"
            class="d-inline-block w-250px ml-3 form-select form-select-sm"
            size="sm"
            @change="changeTypeActivitySelect"
        >
          <template #first>
            <option value=""></option>
          </template>
        </b-form-select>
      </template>
      <template v-else>
        <b-form-input
            list="obchodniZastupceList"
            name="obchodniZastupce"
            class="form-control form-control-sm w-250px ml-3 text-black"
            v-model="filter.obchodniZastupceName"
        />
        <b-datalist
            id="obchodniZastupceList"
            :options="ozUsersNames"
            autocomplete="off"
        />
      </template>

      <b-icon-x-circle
          @click="clearFilter"
          class="pl-2 pt-1 cursor-pointer"
          :class="[(filter.searchString || filter.obchodniZastupceName || krajOption) ? 'filtered' : '']"
          font-scale="1.8"
          title="Čištění filtru"
      />
    </div>

    <div id="Calendar" class="pt-0">
      <calendar-view
          class="fve-calendar"
          :show-date="showDate"
          :startingDayOfWeek="1"
          :displayWeekNumbers="true"
          weekdayNameFormat="long"
          :items="items"
          @click-date="clickDate"
          @click-item="clickItem"
      >
        <calendar-view-header
            slot="header"
            slot-scope="t"
            :header-props="t.headerProps"
            :displayLocale="'cs-CZ'"

            @input="setShowDate"
        />

        <template #item="{ value: item, top }">
          <div
              :key="item.id"
              :class="` ${getItemClasses(item)}`"
              :style="`top:${top};`"
              @click="clickItem(item)"
          >
              {{ item.title }}
          </div>
        </template>
      </calendar-view>
    </div>

    <calendar-tasks-form
        v-if="showTasks"
        :show="showTasks"
        :date="date"
        :dayActivities="dayActivities"
        :activityTypeCodes="lists.activityTypeCodes"
        :obchodniZastupceListById="lists.obchodniZastupceObjById"
        @show-stav-obchodniho-pripadu-form="openStavObchodnihoPripaduModal"
        @show-gdpr-form="openGPPRModal"
        @show-poznamky-k-obchodnimu-pripadu="openPoznamkyKobchodnimuPripaduModal"
        @close-modal="closeCalendarTasksForm"
    />
    <stav-obchodniho-pripadu-form
        v-if="showStavObchodnihoPripaduForm && $store.getters.isStavObchodnihoPripaduAllowed"
        :id="selectedContractId"
        :kodZakazky="selectedKodZakazky"
        :show="showStavObchodnihoPripaduForm"
        @close-modal="showStavObchodnihoPripaduForm = false"
        @form-saved="formSaved"
    />
    <gpdr-form
        v-if="showGDPRForm && $store.getters.isGDPRRodinnePrislusniciAllowed"
        :id="selectedContractId"
        :kodZakazky="selectedKodZakazky"
        :show="showGDPRForm"
        @close-modal="showGDPRForm = false"
        @form-saved="formSaved"
    />
    <poznamky-k-obchodnimu-pripadu-form
        v-if="showPoznamkyKobchodnimuPripaduForm && $store.getters.isPoznamkyKobchodnimuPripaduAllowed"
        :id="selectedContractId"
        :kodZakazky="selectedKodZakazky"
        :show="showPoznamkyKobchodnimuPripaduForm"
        @close-modal="showPoznamkyKobchodnimuPripaduForm = false"
    />
  </b-overlay>
</template>
<script>

import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import axios from "axios";
import _ from "lodash";
import CalendarTasksForm from '@/components/modals/CalendarTasksForm.vue';
import moment from "moment";
import commonMixin from "@/mixins/common.mixin.ts";
import StavObchodnihoPripaduForm from "@/components/modals/StavObchodnihoPripaduForm.vue";
import PoznamkyKObchodnimuPripaduForm from "@/components/modals/PoznamkyPripojit.vue";
import GpdrForm from "@/components/modals/GDPRForm.vue";
import {FILTER_TYPES, LIST_AKTIVITY_OBCHODU_FILTER_TYPES, KRAJ} from "@/constants/form.constants";

const TABS = [
  {tabName: null, link: '#', name: 'Naplánovat jednaní', disabled: true},
  {tabName: null, link: '#', name: 'Naplánovat školení', disabled: true},
  {tabName: null, link: '#', name: 'Naplánovat dovolenou', disabled: true},
  {tabName: null, link: '#', name: 'Výkaz práce'},
];

export default {
  name: 'AktivityObchoduTab',
  props: {
    tabName: {type: String, required: true},
  },
  components: {
    StavObchodnihoPripaduForm, CalendarView, CalendarViewHeader, CalendarTasksForm, GpdrForm, PoznamkyKObchodnimuPripaduForm
  },
  mixins: [commonMixin],
  computed: {
    isFilterTypeNazev() {
      return this.filterType === FILTER_TYPES.NAZEV_V_ADRESARE;
    },
    isFilterTypeKraj() {
      return this.filterType === FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU;
    },
    isTypeActivity() {
      return this.filterType === FILTER_TYPES.TYP_AKTIVITY;
    },
  },
  data() {
    return {
      TABS,
      KRAJ,
      showDate: new Date(),
      items: [],
      isLoading: false,
      showTasks: false,
      date: null,
      dayActivities: [],
      showStavObchodnihoPripaduForm: false,
      showGDPRForm: false,
      showPoznamkyKobchodnimuPripaduForm: false,
      selectedContractId: null,
      selectedKodZakazky: null,
      lists: {
        activityTypeCodes: [],
        typeActivity: [],
        obchodniZastupceFull: [],
        obchodniZastupceObjById: {},
      },
      listFilterTypes: this.getFilteredFilterTypes(),
      filterType: FILTER_TYPES.NAZEV_V_ADRESARE,
      filter: {
        name: null,
        searchString: null,
        obchodniZastupceName: null,
      },
      ozUsers: [],
      ozUsersNames: [],
      activeOzUsersNames: [],
      activeOzUsersInfo: [],
      deactivatedOzUsersNames: [],
      deactivatedOzUsersInfo: {},
      krajOption: null,
      typeActivityOption: null,
      abortController: new AbortController(),
      balanceDate: moment().startOf('month').format('DD.MM.YYYY'),
    }
  },
  methods: {
    getFilteredFilterTypes() {
      return this.getFilteredByOzUsersFilterTypes(LIST_AKTIVITY_OBCHODU_FILTER_TYPES)
    },
    async setShowDate(d) {
      this.showDate = d;
      this.balanceDate = moment(d).format('DD.MM.YYYY');
      await this.getItems();
    },
    clickItem(calendarItem) {
      this.getDayActivities(calendarItem.startDate);
      this.showTasks = true;
    },
    clickDate(date, dayActivities) {
      if (!dayActivities.length) return;
      this.getDayActivities(date);
      this.showTasks = true;
    },
    getActionTitle(item) {
      const {kodZakazky, timeForActivity, activityType, clientLastName} = item;
      const activityTypeStr = _.get(this.lists.activityTypeCodes, activityType);
      return [this.getTimeActivityString(timeForActivity), activityTypeStr, clientLastName, kodZakazky].filter(el => el).join(' - ');
    },
    getSelectedOzUserId() {
      return _.first(this.ozUsers.filter(u => u.fullNameFioTitles === this.filter.obchodniZastupceName))?.id;
    },
    getItemClasses(item) {
      this.getDayActivities(item.startDate, false);
      const classes = ['cv-item', ...item.classes];
      if (item.originalItem?.activityDone) {
        classes.push('activity-done');
      }
      return classes.join(' ');
    },
    getDayActivities(date, setDate = true) {
      const dateForItem = moment(date).format('YYYY-MM-DD');
      this.dayActivities = this.items.filter(i => i.startDate === dateForItem);

      if (!setDate) return;
      this.date = moment(dateForItem).format('DD.MM.YYYY');
    },
    async getItems() {
      this.abortController.abort();
      this.abortController = new AbortController();

      try {
        this.isLoading = true;
        const url = `/calendar/getNaplanovanaAktivitaDates`;
        const {data} = await axios.post(url, {
              filterByUserId: this.getSelectedOzUserId(),
              filter: this.filter,
              balanceDate: this.balanceDate,
            },
            {signal: this.abortController.signal}
        );

        const itemsInfo = _.get(data, 'data.info', []);
        this.items = [];
        itemsInfo.forEach(item => this.items.push({
          startDate: moment(item.naplanovanaAktivitaDate).format('YYYY-MM-DD'),
          title: this.getActionTitle(item),
          ...item,
        }));
        if (this.date) {
          const dateForItem = moment(this.date, 'DD.MM.YYYY').format('YYYY-MM-DD');
          this.dayActivities = this.items.filter(i => i.startDate === dateForItem);
        }
        this.isLoading = false;
      } catch (e) {
        if (!e?.response?.data?.message) {
          // console.error('Operation canceled');
        } else {
          console.error('get calendar items', e.response?.data);
          this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
          this.isLoading = false;
        }
      }
    },
    async formSaved() {
      await this.getItems();
    },
    openStavObchodnihoPripaduModal(contractId, kodZakazky) {
      this.showStavObchodnihoPripaduForm = true;
      this.selectedContractId = contractId;
      this.selectedKodZakazky = kodZakazky;
    },
    openGPPRModal(contractId, kodZakazky) {
      this.showGDPRForm = true;
      this.selectedContractId = contractId;
      this.selectedKodZakazky = kodZakazky;
    },
    openPoznamkyKobchodnimuPripaduModal(contractId, kodZakazky) {
      this.showPoznamkyKobchodnimuPripaduForm = true;
      this.selectedContractId = contractId;
      this.selectedKodZakazky = kodZakazky;
    },
    closeCalendarTasksForm() {
      this.showTasks = false;
      this.dayActivities = [];
      this.date = null;
    },
    async changeFilterType() {
      switch (this.filterType) {
        case FILTER_TYPES.OZ_USERS_ACTIVE:
          this.ozUsers = this.activeOzUsersInfo;
          this.ozUsersNames = this.activeOzUsersNames;
          break;
        case FILTER_TYPES.OZ_USERS_DEACTIVATED:
          this.ozUsers = this.deactivatedOzUsersInfo;
          this.ozUsersNames = this.deactivatedOzUsersNames;
          break;
      }

      await this.clearFilter();
    },
    async clearFilter() {
      this.filter.name = this.filterType;
      this.filter.searchString = null;
      this.filter.obchodniZastupceName = null;
      this.typeActivityOption = null;
      this.krajOption = null;
      await this.getItems();
    },
    async searchByString() {
      this.filter.name = this.filterType;
      await this.getItems();
    },
    async getOzUsersGroupsForUser() {
      try {
        const url = `/user/getOzUsersGroupsForUser`;
        const {data} = await axios.get(url, {});
        this.activeOzUsersNames = data?.data?.activeOzUsersNamesFioTitles || [];
        this.activeOzUsersInfo = data?.data?.activeOzUsersInfo || [];
        this.deactivatedOzUsersNames = data?.data?.deactivatedOzUsersNamesFioTitles || [];
        this.deactivatedOzUsersInfo = data?.data?.deactivatedOzUsersInfo || [];
      } catch (error) {
        console.log(error);
      }
    },
    async getObchodniZastupceList() {
      const url = `/user/getObchodniZastupceList`;
      const {data} = await axios.get(url, { });
      this.lists.obchodniZastupceFull = [..._.get(data, 'data.activeOzUsersInfo' || []), ..._.get(data, 'data.deactivatedOzUsersInfo' || [])];
      this.lists.obchodniZastupceFull.forEach(user => {
        this.lists.obchodniZastupceObjById[user.id] = user.fullName;
      })
    },
    async getConstants() {
      try {
        const dataConst = await this.getConstantsFromServer(['STAV_ACTIVITY_TYPE_CODES', 'STAV_ACTIVITY_TYPE']) || {};
        this.lists.activityTypeCodes = dataConst.STAV_ACTIVITY_TYPE_CODES;
        this.lists.typeActivity = dataConst.STAV_ACTIVITY_TYPE;
      } catch (e) {
        console.error('getConstants', e);
      }
    },
    async changeSelectList(searchString, filterName) {
      this.offset = 0;
      this.filter.name = filterName;
      this.filter.searchString = searchString;
      await this.getItems();
    },
    async changeKrajSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU);
    },
    async changeTypeActivitySelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.TYP_AKTIVITY);
    },
    async changeTab() {
      // await this.getItems();
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getConstants();
    await this.getItems();
    await this.getOzUsersGroupsForUser();
    await this.getObchodniZastupceList();
    this.isLoading = false;
  },
}
</script>
<style>
#Calendar {
  color: #2c3e50;
  height: calc(100vh - 15.5rem);
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

</style>