<template>
  <div class="d-flex w-100">
    <vue-timepicker
        :id="'time'+index"
        :minute-interval="10"
        v-model="cinnost.time"
        auto-scroll
        class="time-picker-activity"
        input-width="110px"
        :hour-range="[[0,9]]"
        hide-disabled-items
        hide-clear-button
        lazy
        input-class="form-control-sm input-timepicker"
        @input="changeData"
    />
    <ValidationProvider
        :rules="{ mustBeInList: { list: obhodniPripadyList }}"
        :name="'cinnostOrderNumber' + index" mode="lazy" v-slot="{ classes, errors }"
        class="w-45 mr-3"
    >
      <b-form-input
          :id="'obhodniPripady' + index"
          :list="'obhodniPripadyList' + index"
          name="orderNumber"
          class="form-control form-control-sm ml-3 w-100"
          placeholder="Obchodní případ / zakázka"
          v-model.trim="orderNumber"
          :title="errors[0]"
          :state="getState(classes) && !validateWithDataFromList('orderNumber', null, 'obhodniPripadyList', true)"
          v-debounce="searchObchodniPripady"
          @change="changeData"
      />
    </ValidationProvider>
    <b-datalist
        :id="'obhodniPripadyList' + index"
        :options="obhodniPripadyList"
        autocomplete="off"
    />

    <ValidationProvider :rules="`max:${noteLength}`" :name="`Poznámka #${index + 1}`" v-slot="{ classes, errors }" class="ml-3 w-45">
      <b-form-input
          :id="'note' + index"
          type="text"
          size="sm"
          placeholder="Poznámka"
          v-model.trim="cinnost.note"
          @input="validateNote"
          :title="errors[0]"
          :state="getState(classes)"
          @change="changeData"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import commonMixin from "@/mixins/common.mixin.ts";
import _ from "lodash";
import axios from "axios";

const noteLength = 100;

export default {
  components: {VueTimepicker},
  mixins: [commonMixin],
  props: {
    cinnost: { type: Object, default: () => ({time: {HH: '00', mm: '00'}, contractId: null, note: null, orderNumber: null}) },
    index: { type: Number, required: true },
  },
  data() {
    return {
      noteLength,
      orderNumber: '',
      obhodniPripadyList: [],
      obhodniPripadyListFull: [],
    };
  },
  computed: {
  },
  methods: {
    validateNote() {
      this.$emit('validation-error', this.cinnost.note.length > this.noteLength);
    },
    validateWithDataFromList(key, isInput, listName, isCanBeEmpty = false) {
      if (isCanBeEmpty && !this[key]) return false;
      const isEqual = this[listName || key].includes(_.get(this, key));
      return isInput && !isEqual ? false : !isEqual;
    },
    fillObhodniPripadyList(list) {
      this.obhodniPripadyList = [];
      list.map(info => {
        this.obhodniPripadyList.push(info.orderNumber);
      });
      this.obhodniPripadyListFull = list;
    },
    async searchObchodniPripady() {
      try {
        if (this.orderNumber?.length < 3 || this.getContractId()) {
          return;
        }

        const url = `/calendar/searchObchodniPripady`;
        const {data: {data}} = await axios.get(url, {params: {search: this.orderNumber}});
        this.fillObhodniPripadyList(data?.info || []);
      } catch (e) {
        console.error(e);
      }
    },
    getContractId() {
      const contract = this.obhodniPripadyListFull.find(info => info.orderNumber === this.orderNumber);
      return contract?.contractId;
    },
    changeData() {
      this.cinnost.contractId = this.getContractId();
      this.$emit('change-data', { cinnost: this.cinnost, index: this.index });
    },
  },
  async mounted() {
    if (this.cinnost?.orderNumber) {
      this.orderNumber = this.cinnost.orderNumber;
      this.obhodniPripadyList = [this.cinnost.orderNumber];
      this.obhodniPripadyListFull = [{orderNumber: this.cinnost.orderNumber, contractId: this.cinnost.contractId}];
    }
  },
};
</script>
